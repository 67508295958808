<template>
  <div class="BigBox">
    <!-- 头部 -->
    <div class="Title_BN"></div>
    <!-- 主体盒子 -->
    <div class="ContentBox">
      <!-- 古镇简介 -->
      <div class="At_BriefITitle">
        <div style="width: 27%;height: 100%;">
          <img style="width: 100%;height: 100%;" src="../../assets/VRVisit/JG_ParticularsList_AT_JJ.png" alt="" />
        </div>
      </div>
      <div class="At_BriefIText">
        <div id="At_BriefITextBox" v-html="GZjj"></div>
      </div>
      <!-- 景区景点 -->
      <div class="Sa_BriefITitle">
        <div style="width: 24%;height: 100%;">
          <img style="width: 100%;height: 100%;" src="../../assets/VRVisit/JG_ParticularsList_Sa_JD.png" alt="" />
        </div>
      </div>
      <div class="Sa_BriefITextBox">
        <div class="Sa_BriefIContent">
          <div style="width: 80%;height: 82%; ">
            <div v-text="JQJD[0]?.mainTitle" class="Sa_BriefIContent-title"></div>
            <div v-text="JQJD[0]?.summary" class="Sa_BriefIContent-text"></div>
          </div>
        </div>
        <div class="Sa_BriefIImg">
          <img style="width: 100%;height: 100%;" :src="JQJD[0]?.thumbnail.resourceUrl" alt="图片解析错误" />
        </div>
        <div class="Sa_BriefITab">
          <li v-for="(item, index) in JQJDTwo" @mouseenter="change(index)" :class="{ actived: active == index }"
            :key="item.id">
            <div>
              <span>{{ item.propaganda }}</span>
              <img style="transform: scale(0.7);" src="../../assets/Right_JT.png" alt="" />
            </div>
          </li>
        </div>
      </div>
      <!-- 靖港文化 -->
      <div class="JG_CultureTitle">
        <!-- 标题图片 -->
        <div style="width: 24%;height: 100%;">
          <img style="width: 100%;height: 100%;" src="../../assets/VRVisit/JG_Culture.png" alt="" />
        </div>
      </div>
      <div class="JG_CultureContentBox">
        <!-- 左边民风民俗 -->
        <div class="JG_CultureContentLeft">
          <div style="height: 48%;width: 100%;">
            <img :src=MSMF[MFImg]?.thumbnail.resourceUrl style="height: 100%;width: 100%;" alt="" />
          </div>
          <div class="JG_CultureContentTabBox">
            <!-- 第一个 -->
            <div class="JG_xinChun JG_Mfms_one" style="margin-top:70px;">
              <!-- 箭头图片 -->
              <div class="festival" v-for="(MSMF, index) in MSMF" :key="index">
                <img class="jianTou" src="../../assets/VRVisit/JG_Culture_JT.png" alt="" />
                <span class="JG_xinChun_left" @mouseenter="MFMSTab(index)">{{ MSMF.mainTitle }}</span>
                <span class="JG_xinChun_right">1月24日-1月30日</span>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="JG_CultureContentRight">
          <!-- 右上靖港非遗 -->
          <div style="height: 48%;width: 100%;display: flex;">
            <div style="height: 100%;width: 65%;">
              <img :src="FWZ[FWZImg]?.thumbnail.resourceUrl" style="width: 100%;height: 100%;" alt="" />
            </div>
            <div class="JG_CultureContent_JgfyTab">
              <ul>
                <li v-for="(FWZ, index) in FWZ" :key="index" @mouseenter="changFeiYi(index)" class="JgfyTabText">
                  <img class="FYImg" style="transform: scale(0.8);" src="../../assets/VRVisit/JG_Culture_Yuan.png"
                    alt="" />
                  {{ FWZ.mainTitle }}
                </li>
              </ul>
            </div>
          </div>
          <!-- 右下饮食文化 -->
          <div class="JG_CultureContentRightBottomBox">
            <div class="JG_CultureContent_YswhTab"></div>
            <div class="JG_CultureContent_YswhImgBox">
              <div class="JG_CultureContent_YswhImgJSBox"></div>
              <div style="width: 57%;height: 100%;"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 靖港影像志 -->
      <div class="JG_Videos">
        <div style="width: 24%;height: 100%;">
          <img style="width: 100%;height: 100%;" src="../../assets/VRVisit/JG_Videos.png" alt="" />
        </div>
      </div>
      <ul class="JG_VideosConBox">
        <li v-for="(item, index) in JGYXZ" class="JG_VideosCon" :key="index" @click="videolog()">
          <img style="width: 92%;height: 70%;cursor: pointer;" :src="item.thumbnail?.resourceUrl" alt="" />
          <div style="width: 100%; font-size: 22px;color: #696969;display: flex;flex-direction:row;cursor: pointer;">
            《{{ item.thumbnail.title }}》
          </div>
          <div style="width: 90%; height: 30px; font-size: 18px;display: flex;flex-direction:row;align-items: center;">
            <img src="../../assets/VRVisit/JG_Videos_BFL.png" alt="" />
            <span style="margin:0 5px ;">
              {{ item.viewNum }}
            </span>
          </div>
        </li>
      </ul>
      <!-- 景观基因 -->
      <div class="JG_LanGene">
        <div style="width: 24%;height: 100%;">
          <img style="width: 100%;height: 100%;" src="../../assets/VRVisit/JG_Jy.png" alt="" />
        </div>
      </div>
      <div class="JG_LanGeneConBox">
        <img style="width: 100%;height: 100%;" src="../../assets/VRVisit/JG_Jy_con.png" alt="" />
      </div>
    </div>
  </div>

  <!-- 底栏 -->
  <Bottom_Bar></Bottom_Bar>
  <!-- 导航 -->
  <Navigation_Buttons></Navigation_Buttons>
</template>

<script>
import $ from "jquery";
import Bottom_Bar from "../Public/Bottom_Bar.vue";
import Navigation_Buttons from "../Public/Navigation_Buttons.vue";
let opp = ''
export default {
  data() {
    return {
      MFImg: 0,
      FWZImg: 0,
      active: 0,
      DataList: [],
      JGList: "",
      ImgList: [],
      GZjj: "",
      JQJD: [],
      JQJDTwo: [],
      JGYXZ: [],
      JGWH: [],
      MSMF: [],
      FWZ: [],
      NRID: ''
    };
  },
  components: {
    Bottom_Bar,
    Navigation_Buttons,
  },
  mounted() {
  },
  created() {
    this.postJGList();

  },
  methods: {
    // 获取数据
    postJGList() {
      var axios = require("axios");
      var data = JSON.stringify({
        pageModuleCode: "gz_xiangqing",
        pulishId: this.$route.query.id
      });

      var config = {
        method: "post",
        url: this.$Schttp + "/vtp/app/contentPublish/vrTownDetail",
        headers: { "Content-Type": "application/json", },
        data: data,
      };

      axios(config).then((res) => {
        let JGList = res.data.data;
        this.GZjj = JGList.townInfo.summary;
        this.JQJD = JGList.builds;
        this.JQJDTwo = this.editModel = JSON.parse(JSON.stringify(this.JQJD));
        this.JGYXZ = JGList.graphys;
        this.MSMF = JGList.customs;
        this.FWZ = JGList.heritages;
        // console.log(21212121)
        // console.log(this.JGWH)
      });
    },

    // 民风民俗 字体效果
    MFMSTab(index) {
      this.MFImg = index
    },

    // 非遗Tab
    JGWHchange(index) {
      this.active = index;
    },

    /*景区景点切换*/
    change(index) {
      this.active = index;
      // console.log(this.JQJDp[2]);
      if (index == 0) {
        this.JQJD[0] = this.JQJDTwo[0];
      } else {
        this.JQJD[0] = this.JQJD[index];
      }
    },
    videolog() {
      this.$router.push("/jG_VideoLog");
    },
    changFeiYi(i) {
      this.FWZImg = i
    }
  },
};
</script>

<style lang="less" scoped>
@import url("./JG_ParticularsList.less");
</style>


